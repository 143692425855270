"use client";
import React from "react";
import { useEffect } from "react";
import { usePathname } from "next/navigation";

function ScrollProvider({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return <>{children}</>;
}

export default ScrollProvider;
