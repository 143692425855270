import { SxProps, useMediaQuery } from "@mui/material";
import theme from "@/lib/theme";
import { Button, Icon } from "@/shared/components";
import { IconsNames } from "@/shared/components/icon.component/icon.types";
import colors from "@/lib/palette";

type Props = {
  text: string;
  icon: IconsNames;
  disabled?: boolean;
  sx?: SxProps;
  onClick?: () => void;
};

const HeaderButton = (props: Props) => {
  const { sx, text, icon, disabled, onClick } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const styles: SxProps = {
    height: {
      xs: "36px",
      lg: "48px",
      xl: "60px",
    },
    fontSize: {
      lg: "16px",
      xl: "20px",
    },

    ...(isMobile && {
      border: "1px solid #C9D3FF",

      "& *": {
        color: colors.blue,
        fill: colors.blue,
      },
    }),

    paddingLeft: {
      xs: "0px",
    },
    paddingRight: {
      xs: "0px",
    },
    ...sx,
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      component="span"
      variant="outlined-secondary"
      disabled={disabled}
      prepend={!isMobile && <Icon icon={icon} width={20} height={20} />}
      sx={styles}
      onClick={handleOnClick}
    >
      {isMobile ? (
        <Icon icon={icon} width={16} height={16} color={colors.blue} />
      ) : (
        text
      )}
    </Button>
  );
};

export default HeaderButton;
